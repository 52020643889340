/*
@tailwind base;

@tailwind components;
*/

.bg-black {
  background-color: #22292F
}

.bg-yellow-400 {
  background-color: #FFEE58
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.rounded-8 {
  border-radius: .8rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.border-none {
  border-style: none
}

.border-1 {
  border-width: 1px
}

.border {
  border-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-box;
  display: flex
}

.inline-flex {
  display: -webkit-inline-box;
  display: inline-flex
}

.grid {
  display: grid
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column
}

.flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
          flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-no-wrap {
  flex-wrap: nowrap
}

.items-start {
  -webkit-box-align: start;
          align-items: flex-start
}

.items-end {
  -webkit-box-align: end;
          align-items: flex-end
}

.items-center {
  -webkit-box-align: center;
          align-items: center
}

.items-baseline {
  -webkit-box-align: baseline;
          align-items: baseline
}

.self-end {
  align-self: flex-end
}

.self-center {
  align-self: center
}

.justify-start {
  -webkit-box-pack: start;
          justify-content: flex-start
}

.justify-end {
  -webkit-box-pack: end;
          justify-content: flex-end
}

.justify-center {
  -webkit-box-pack: center;
          justify-content: center
}

.justify-between {
  -webkit-box-pack: justify;
          justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.flex-1 {
  -webkit-box-flex: 1;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-box-flex: 1;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-box-flex: 0;
          flex-grow: 0
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.float-right {
  float: right
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-28 {
  height: 2.8rem
}

.h-36 {
  height: 3.6rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-64 {
  height: 6.4rem
}

.h-224 {
  height: 22.4rem
}

.h-320 {
  height: 32rem
}

.h-360 {
  height: 36rem
}

.h-400 {
  height: 40rem
}

.h-512 {
  height: 51.2rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.m-0 {
  margin: 0
}

.m-4 {
  margin: 0.4rem
}

.m-8 {
  margin: 0.8rem
}

.m-auto {
  margin: auto
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.mx-28 {
  margin-left: 2.8rem;
  margin-right: 2.8rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-my-8 {
  margin-top: -0.8rem;
  margin-bottom: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-2 {
  margin-top: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mr-10 {
  margin-right: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.ml-20 {
  margin-left: 2rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mb-36 {
  margin-bottom: 3.6rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mt-64 {
  margin-top: 6.4rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

.max-h-200 {
  max-height: 20rem
}

.max-h-288 {
  max-height: 28.8rem
}

.max-h-512 {
  max-height: 51.2rem
}

.max-w-48 {
  max-width: 4.8rem
}

.max-w-136 {
  max-width: 13.6rem
}

.max-w-256 {
  max-width: 25.6rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-xs {
  max-width: 32rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-400 {
  min-height: 40rem
}

.min-h-512 {
  min-height: 51.2rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-256 {
  min-width: 25.6rem
}

.min-w-384 {
  min-width: 38.4rem
}

.min-w-400 {
  min-width: 40rem
}

.min-w-md {
  min-width: 64rem
}

.min-w-xl {
  min-width: 96rem
}

.opacity-0 {
  opacity: 0
}

.opacity-50 {
  opacity: 0.5
}

.opacity-100 {
  opacity: 1
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-scroll {
  overflow: scroll
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-10 {
  padding: 1.0rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-2 {
  padding-left: 0.2rem;
  padding-right: 0.2rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

.px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem
}

.px-68 {
  padding-left: 6.8rem;
  padding-right: 6.8rem
}

.pr-0 {
  padding-right: 0
}

.pl-0 {
  padding-left: 0
}

.pr-4 {
  padding-right: 0.4rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pt-6 {
  padding-top: 0.6rem
}

.pb-6 {
  padding-bottom: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-10 {
  padding-top: 1.0rem
}

.pr-10 {
  padding-right: 1.0rem
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pr-32 {
  padding-right: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-36 {
  padding-bottom: 3.6rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-black {
  color: #22292F
}

.text-white {
  color: #FFF
}

.text-gray-500 {
  color: #9E9E9E
}

.text-gray {
  color: #9E9E9E
}

.text-red-500 {
  color: #F44336
}

.text-blue-500 {
  color: #2196F3
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-17 {
  font-size: 1.7rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-2xl {
  font-size: 2.4rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.align-middle {
  vertical-align: middle
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre {
  white-space: pre
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.w-12 {
  width: 1.2rem
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-72 {
  width: 7.2rem
}

.w-92 {
  width: 9.2rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-136 {
  width: 13.6rem
}

.w-160 {
  width: 16rem
}

.w-200 {
  width: 20rem
}

.w-224 {
  width: 22.4rem
}

.w-384 {
  width: 38.4rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-1\/5 {
  width: 20%
}

.w-3\/5 {
  width: 60%
}

.w-4\/5 {
  width: 80%
}

.w-1\/12 {
  width: 8.333333%
}

.w-6\/12 {
  width: 50%
}

.w-7\/12 {
  width: 58.333333%
}

.w-8\/12 {
  width: 66.666667%
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

.gap-2 {
  grid-gap: 0.2rem;
  gap: 0.2rem
}

.gap-4 {
  grid-gap: 0.4rem;
  gap: 0.4rem
}

.gap-8 {
  grid-gap: 0.8rem;
  gap: 0.8rem
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.-rotate-90 {
  --transform-rotate: -90deg
}

.transition-all {
  -webkit-transition-property: all;
  transition-property: all
}

.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

.duration-300 {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms
}

@media (min-width: 600px) {

  .sm\:flex {
    display: -webkit-box;
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:rotate-90 {
    --transform-rotate: 90deg
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-box;
    display: flex
  }

  .md\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column
  }

  .md\:ml-16 {
    margin-left: 1.6rem
  }

  .md\:pr-0 {
    padding-right: 0
  }

  .md\:pb-10 {
    padding-bottom: 1.0rem
  }

  .md\:pr-48 {
    padding-right: 4.8rem
  }

  .md\:w-200 {
    width: 20rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:rotate-0 {
    --transform-rotate: 0
  }
}

@media (min-width: 1280px) {

  .lg\:hidden {
    display: none
  }

  .lg\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row
  }

  .lg\:justify-start {
    -webkit-box-pack: start;
            justify-content: flex-start
  }

  .lg\:justify-end {
    -webkit-box-pack: end;
            justify-content: flex-end
  }

  .lg\:h-512 {
    height: 51.2rem
  }

  .lg\:h-640 {
    height: 64rem
  }

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:mt-0 {
    margin-top: 0
  }

  .lg\:ml-12 {
    margin-left: 1.2rem
  }

  .lg\:ml-48 {
    margin-left: 4.8rem
  }

  .lg\:min-h-640 {
    min-height: 64rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pb-0 {
    padding-bottom: 0
  }

  .lg\:pr-10 {
    padding-right: 1.0rem
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  .lg\:text-14 {
    font-size: 1.4rem
  }

  .lg\:text-2xl {
    font-size: 2.4rem
  }

  .lg\:w-128 {
    width: 12.8rem
  }

  .lg\:w-360 {
    width: 36rem
  }

  .lg\:w-4\/5 {
    width: 80%
  }
}